import React, { Component } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { listQuestionaryInteractions} from '../../../graphql/queries'
import { updateQuestionaryInteraction } from '../../../graphql/mutations'
import { CSVReader } from 'react-papaparse'
import { Container } from 'react-bootstrap'

const buttonRef = React.createRef()

export default class QuestionaryInteraction extends Component {

    constructor() {
        super()
        this.state = {
            headersMap: null,
            isErrors: false,
            errors: '',
            numero_registros_actualizados: 0,
            numero_registros_no_actualizados: 0,
        }
      }

    handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
          buttonRef.current.open(e)
        }
    }
    
    handleOnFileLoad = async(data) => {
      
      this.setState({isErrors: false, errors: ''})

      // Headers
      if (data.length <= 0) {
          await this.setState({isErrors: true, errors: 'Archivo vacio'})
      } else {
          if (!this.hasMandatoryHeaders(data[0].data)) {
              this.setState({isErrors: true, errors: 'No están completas las columnas del encabezado, mínimo: (clientID,state_0,state_1,state_2)'})
          }
          else {
              let tempHeadersMap = new Map()
              for (let i=0; i < data[0].data.length; i++) {
                  tempHeadersMap.set(data[0].data[i], i.toString());
              }
              await this.setState({headersMap: tempHeadersMap})
          }            
      }

      if (!this.state.isErrors) {
        console.log('---------------------------')
        console.log('data: ', data)
        console.log('headersMap: ', this.state.headersMap)
        console.log('---------------------------')

        let actualClientIdIndex = 0
        let actualState0Index = 0
        let actualState1Index = 0
        let actualState2Index = 0
        let tempHeadersMap = null
        let temp_numero_registros_actualizados = 0

        // Rows
        for (let i = 1; i < data.length; i++) {

            if (i === 1) {
              tempHeadersMap = this.state.headersMap
              actualClientIdIndex = parseInt(tempHeadersMap.get('clientID'))
              actualState0Index = parseInt(tempHeadersMap.get('state_0'))
              actualState1Index = parseInt(tempHeadersMap.get('state_1'))
              actualState2Index = parseInt(tempHeadersMap.get('state_2'))
            }
            
            // Array
            let notMandatoryArray = [actualClientIdIndex,actualState0Index,actualState1Index,actualState2Index]
            let tempSubDataArray = data[i].data
            let dataClientJson = {}
            
            for (let j = 0; j < tempSubDataArray.length; j++) {
              
                if (!notMandatoryArray.includes(j)) {
                    
                    let key = this.getKeyNameFromMapByValue(tempHeadersMap,j)
                    console.log('no mandatory key: ', key, ' ',tempSubDataArray[j])
                    dataClientJson[key] = tempSubDataArray[j]
                }

            }
            
            console.log('---------------------------')
            console.log('dataClientJson: ', dataClientJson)
            console.log('---------------------------')
            debugger
            const filterByQuestionaryClientID = {
              filter: {clientID: {eq: data[i].data[actualClientIdIndex]}}  
            }
            let tempListQuestionaryInteractionsResult = await API.graphql(graphqlOperation(listQuestionaryInteractions, filterByQuestionaryClientID))

            if (tempListQuestionaryInteractionsResult.data.listQuestionaryInteractions.items.length > 0) {
              let tempQuestionaryInteraction = tempListQuestionaryInteractionsResult.data.listQuestionaryInteractions.items[0]
              debugger
              console.log('---------------------------')
              console.log('tempQuestionaryInteraction: ', tempQuestionaryInteraction)
              console.log('---------------------------')

              tempQuestionaryInteraction.state_0 = data[i].data[actualState0Index]
              tempQuestionaryInteraction.state_1 = data[i].data[actualState1Index]
              tempQuestionaryInteraction.state_2 = data[i].data[actualState2Index]
              tempQuestionaryInteraction.dataClientJson = dataClientJson

              delete tempQuestionaryInteraction.questionAnswers
              delete tempQuestionaryInteraction.interactions
              delete tempQuestionaryInteraction.questionary
              
              console.log('---------------------------')
              console.log('tempQuestionaryInteraction after delete children: ', tempQuestionaryInteraction)
              console.log('---------------------------')
              debugger
              await API.graphql(graphqlOperation(updateQuestionaryInteraction, { input: tempQuestionaryInteraction }));
              temp_numero_registros_actualizados += 1
              this.setState({numero_registros_actualizados: temp_numero_registros_actualizados})
            }
          }
      }
      
      
    }
    
    handleOnError = (err, file, inputElem, reason) => {
      console.log(err)
    }
    
    handleOnRemoveFile = (data) => {
      console.log('---------------------------')
      console.log(data)
      console.log('---------------------------')
    }
    
    handleRemoveFile = (e) => {
      // Note that the ref is set async, so it might be null at some point
      if (buttonRef.current) {
        buttonRef.current.removeFile(e)
      }
    }
    
    hasMandatoryHeaders (arr) {
      if (arr.includes('clientID') && arr.includes('state_0') && arr.includes('state_1') && arr.includes('state_2')) {
          return true
      }else{
          return false
      }
    }

    getKeyNameFromMapByValue (map, i) {
      for (var [key, value] of map) {
          if (parseInt(value) === i) {
              return key
          }
      }
    }

    render() {
      let {isErrors} = this.state
      let {errors} = this.state
      let {numero_registros_actualizados} = this.state

      const renderErrors = () => {
          if(isErrors){
            return (
              <div className="alert alert-warning" role="alert">
                  {errors}
              </div>
            )
          }
        }

        const renderRegistersUpdated = () => {
            return (
              <div className="alert alert-warning" role="alert">
                  {numero_registros_actualizados}
              </div>
            )
        }

      return (
        <Container>
          <div className="row">
            
              <div className="col-md-12">
                <CSVReader
                ref={buttonRef}
                onFileLoad={this.handleOnFileLoad}
                onError={this.handleOnError}
                noClick
                noDrag
                onRemoveFile={this.handleOnRemoveFile}
            >
                {({ file }) => (
                <aside
                    style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 10
                    }}
                >
                    <button
                    type='button'
                    onClick={this.handleOpenDialog}
                    style={{
                        borderRadius: 0,
                        marginLeft: 0,
                        marginRight: 0,
                        width: '40%',
                        paddingLeft: 0,
                        paddingRight: 0
                    }}
                    >
                    Browse file
                    </button>
                    <div
                    style={{
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: '#ccc',
                        height: 45,
                        lineHeight: 2.5,
                        marginTop: 5,
                        marginBottom: 5,
                        paddingLeft: 13,
                        paddingTop: 3,
                        width: '60%'
                    }}
                    >
                    {file && file.name}
                    </div>
                    <button
                    style={{
                        borderRadius: 0,
                        marginLeft: 0,
                        marginRight: 0,
                        paddingLeft: 20,
                        paddingRight: 20
                    }}
                    onClick={this.handleRemoveFile}
                    >
                    Remove
                    </button>
                </aside>
                )}
            </CSVReader>
            </div>

            <div className='row'>
              <div className="col-md-12">
                {renderErrors()}
              </div>
            </div>

            <div className='row'>
              <div className="col-md-6">
                Registros actualizados: 
              </div>
              <div className="col-md-6">
                {renderRegistersUpdated()}
              </div>
            </div>

          </div>
        </Container>
      )
  }
}
