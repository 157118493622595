import React, { Component } from 'react'
import { Button, Form, Col } from 'react-bootstrap'

export default class End extends Component {

    constructor(props) {
        super(props)
        this.state = { 
            clientID: props.clientID,
            nombreApellido: '',
            endTextHTML: props.endTextHTML,
            isFormSubmited: false,
            isDataClientFormButtonDisable: true
        }
    }


    render() {
        let {isFormSubmited} = this.state

        if (!isFormSubmited) {
            return (
                <Form>
                    
                    <Form.Group as={Col} controlId="formGridCompleteName">
                        <Form.Label>Nombre y Apellido</Form.Label>
                        <Form.Control placeholder="Jhon Smith" />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridAge">
                        <Form.Label>Edad</Form.Label>
                        <Form.Control />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Correo</Form.Label>
                        <Form.Control type="email" placeholder="Ingrese el correo" />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Teléfono</Form.Label>
                        <Form.Control type="phone" placeholder="Ingrese el teléfono" />
                    </Form.Group>
                    
                    <Button 
                        variant="primary" 
                        type="submit"
                        onClick={this.handleUpdateQuestionaryConfig}
                        disabled={this.state.isDataClientFormButtonDisable}>
                        Submit
                    </Button>
                    
                </Form>
                    
            )
        }else {
            <p>{this.state.endTextHTML}</p>
        }
    }
}
