import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Container, Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap'

class Header extends Component {

  constructor(props) {
    super(props)
    
    this.state = {
      recording: false,
      videos: [],
    };
  }

  render() {
    return (  
      <Container>
        <Navbar bg="light" expand="lg">
        <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link className="nav-link" to="/admon/dashboard">Dashboard</Link>
            <NavDropdown title="Configuración" id="basic-nav-dropdown">
              {/* <NavDropdown.Item href="/admon/questionaryConfig">Cuestionario</NavDropdown.Item> */}
              <NavDropdown.Item><Link className="nav-link" to="/admon/questionaryConfig">Configuración</Link></NavDropdown.Item>
              {/* <Link className="nav-link" to="/admon/questionaryConfig">Configuración</Link> */}
              {/* <Link className="nav-link" to="/admon/crud_questionary">Cuestionario</Link> */}
              <NavDropdown.Item><Link className="nav-link" to="/admon/crud_questionary">Cuestionario</Link></NavDropdown.Item>
              <NavDropdown.Item><Link className="nav-link" to="/admon/update_questionary_interaction">Actualizar C. Interacciones</Link></NavDropdown.Item>
              <NavDropdown.Divider />
              {/* <NavDropdown.Item href="#action/3.4">Areas comerciales de la ciudad</NavDropdown.Item> */}
            </NavDropdown>
            {/* <Nav.Link href="/stores">Tiendas</Nav.Link> */}
            {/* <Nav.Link href="/deals">Ofertas</Nav.Link> */}
            {/* <Link className="nav-link" to="/admon/update_questionary_interaction">Actualizar C. Interacciones</Link> */}
          </Nav>
          {/* <Form inline>
            <FormControl type="text" placeholder="Buscar" className="mr-sm-2" />
            <Button variant="outline-success">Buscar</Button>
          </Form> */}
        </Navbar.Collapse>
      </Navbar>
      </Container>
    )
  }

}

export default Header
