import React, { Component } from 'react'

export default class QuestionHeader extends Component {
    render() {
        return (
           <div>
           
            </div>
        )
    }
}
