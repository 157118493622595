import React, { Component } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { updateQuestionaryConfig } from '../../../graphql/mutations'
import { listQuestionaryConfigs} from '../../../graphql/queries'
import { CheckBox, View, Text, StyleSheet } from 'react-native'
import { onUpdateQuestionaryConfig } from '../../../graphql/subscriptions'
import { Container } from 'react-bootstrap'

class CreateQuestionaryConf extends Component {

  constructor() {
    super()
    this.state = {
        ID: null,
        isShowReactCursorPositionLabel: false,
        isCreateInteractionQuestionary: false,
        isCreateInteractionQuestionaryButton: false,
        isDataClient: false,
        externalLink: "",
        isExternalLink: false,
        startTextHTML: "",
        endTextHTML: "",
        layout: "",
        isCRUDButtonDisable: true
    }
  }

  componentDidMount = async () => {
    await this.loadQuestionaryConfig()

    this.updateQuestionaryConfigListener = API.graphql(graphqlOperation(onUpdateQuestionaryConfig))
        .subscribe({
            next: questionaryConfigData => {
                const updatedQuestionaryConfig = questionaryConfigData.value.data.onUpdateQuestionaryConfig
                this.setState({
                  ID: updatedQuestionaryConfig.id,
                  isShowReactCursorPositionLabel: updatedQuestionaryConfig.isShowReactCursorPositionLabel,
                  isCreateInteractionQuestionary: updatedQuestionaryConfig.isCreateInteractionQuestionary,
                  isCreateInteractionQuestionaryButton: updatedQuestionaryConfig.isCreateInteractionQuestionaryButton,
                  isDataClient: updatedQuestionaryConfig.isDataClient,
                  externalLink: updatedQuestionaryConfig.externalLink,
                  isExternalLink: updatedQuestionaryConfig.isExternalLink,
                  isCRUDButtonDisable: false,
                  startTextHTML: updatedQuestionaryConfig.startTextHTML,
                  endTextHTML: updatedQuestionaryConfig.endTextHTML,
              }) 
              console.log('componentDidMount updateQuestionaryConfigListener')
            }
        })
  }

  loadQuestionaryConfig = async () => {
    const result = await API.graphql(graphqlOperation(listQuestionaryConfigs, {authMode: 'API_KEY'}))
    if (result.data.listQuestionaryConfigs.items.length > 0) {
        this.setState({
            ID: result.data.listQuestionaryConfigs.items[0].id,
            isShowReactCursorPositionLabel: result.data.listQuestionaryConfigs.items[0].isShowReactCursorPositionLabel,
            isCreateInteractionQuestionary: result.data.listQuestionaryConfigs.items[0].isCreateInteractionQuestionary,
            isCreateInteractionQuestionaryButton: result.data.listQuestionaryConfigs.items[0].isCreateInteractionQuestionaryButton,
            isDataClient: result.data.listQuestionaryConfigs.items[0].isDataClient,
            externalLink: result.data.listQuestionaryConfigs.items[0].externalLink,
            isExternalLink: result.data.listQuestionaryConfigs.items[0].isExternalLink,
            isCRUDButtonDisable: false,
        }) 
    }
    console.log(" Questionary Config: ", result.data.listQuestionaryConfigs.items)
    console.log(" loadQuestionaryConfig this.state: ", this.state)
  }

  handleChangeTextValues = event => this.setState({
    [event.target.name] : event.target.value
  })

  handleUpdateQuestionaryConfig = async event => {
    
    console.log('### handleAddQuestionaryConfig, this.state: ', this.state)

    const input = {
        id: this.state.ID,
        isShowReactCursorPositionLabel: this.state.isShowReactCursorPositionLabel,
        isCreateInteractionQuestionary: this.state.isCreateInteractionQuestionary,
        isCreateInteractionQuestionaryButton: this.state.isCreateInteractionQuestionaryButton,
        isDataClient: this.state.isDataClient,
        externalLink: this.state.externalLink,
        isExternalLink: this.state.isExternalLink,
    }

    console.log('### handleAddRegister, input: ', input)

    await API.graphql(graphqlOperation(updateQuestionaryConfig, { input }));

  }


  // Fillout the Post data when change
  handleChangeRegister = event => this.setState({  
    [event.target.name] : event.target.value.toUpperCase() 
  }
  )

  setSelectionIsCreateInteractionQuestionary = () => {
    if (this.state.isCreateInteractionQuestionary){
      this.setState({isCreateInteractionQuestionary: false})
    }else{
      this.setState({isCreateInteractionQuestionary: true})
    }
  }

  setSelectionIsCreateInteractionQuestionaryButton = () => {
    if (this.state.isCreateInteractionQuestionaryButton){
      this.setState({isCreateInteractionQuestionaryButton: false})
    }else{
      this.setState({isCreateInteractionQuestionaryButton: true})
    }
  
  }

  setSelectionIsDataClient = () => {
    if (this.state.isDataClient){
      this.setState({isDataClient: false, isExternalLink: true})
    }else{
      this.setState({isDataClient: true, isExternalLink: false})
    }
  }

  setSelectionIsExternalLink = () => {
    if (this.state.isExternalLink){
      this.setState({isExternalLink: false, isDataClient: true})
    }else{
      this.setState({isExternalLink: true, isDataClient: false})
    }
  }

  render() {
    let {isExternalLink} = this.state

    const renderExternaLink = () => {
      if(isExternalLink){
        return (
          <div className="col-md-12">
                <input style={{ font: '19px'}} 
                type="text" placeholder="Enlace externo"
                name="title"
                required
                value={this.state.externalLink}
                onChange={this.handleChangeTextValues}
                />
            </div>
        )
      }
    }
    
    return (
        <Container>
          <div className="row">

          <div className="col-md-6">
            <View style={styles.checkboxContainer}>
                <CheckBox
                value={this.state.isCreateInteractionQuestionary}
                onValueChange={this.setSelectionIsCreateInteractionQuestionary}
                style={styles.checkbox}
                />
                <Text style={styles.label}>Guardar interacciones del Cuestionario?</Text>
            </View>
          </div>

          <div className="col-md-6">
            <View style={styles.checkboxContainer}>
                <CheckBox
                value={this.state.isCreateInteractionQuestionaryButton}
                onValueChange={this.setSelectionIsCreateInteractionQuestionaryButton}
                style={styles.checkbox}
                />
                <Text style={styles.label}>Guardar interacciones los Botones?</Text>
            </View>
          </div>

          <div className="col-md-6">
            <View style={styles.checkboxContainer}>
                <CheckBox
                value={this.state.isDataClient}
                onValueChange={this.setSelectionIsDataClient}
                style={styles.checkbox}
                />
                <Text style={styles.label}>Guardar información del Cliente?</Text>
            </View>
          </div>

          <div className="col-md-6">
            <View style={styles.checkboxContainer}>
                <CheckBox
                value={this.state.isExternalLink}
                onValueChange={this.setSelectionIsExternalLink}
                style={styles.checkbox}
                />
                <Text style={styles.label}>Redireccionar a un enlace externo?</Text>
            </View>
          </div>

          {renderExternaLink()}

          <div className="col-md-12">
              <input style={{ font: '19px'}} 
              type="text" placeholder="Texto inicio HTML"
              name="startTextHTML"
              required
              value={this.state.startTextHTML}
              onChange={this.handleChangeTextValues}
              />
          </div>

          <div className="col-md-12">
              <input style={{ font: '19px'}} 
              type="text" placeholder="Texto final HTML"
              name="endTextHTML"
              required
              value={this.state.endTextHTML}
              onChange={this.handleChangeTextValues}
              />
          </div>
          
          <div className="col-md-12">
              <button
                  className="btn btn-primary"
                  type="button"
                  id="start"
                  onClick={this.handleUpdateQuestionaryConfig}
                  disabled={this.state.isCRUDButtonDisable}
              >ACTUALIZAR</button>
          </div>
          
        </div>
        </Container>
      )
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  checkboxContainer: {
    flexDirection: "row",
    marginBottom: 20,
  },
  checkbox: {
    alignSelf: "center",
  },
  label: {
    margin: 8,
  },
  baseText: {
    fontFamily: "Cochin"
  },
  titleText: {
    fontSize: 20,
    fontWeight: "bold"
  }
})

export default CreateQuestionaryConf