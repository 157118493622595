import React, { Component } from 'react'

// GraphQL
import { API, graphqlOperation } from 'aws-amplify'
import { listQuestionaryInteractions } from '../../../graphql/queries'

// Bootstrap
import { Alert } from 'react-bootstrap'

class Dashboard extends Component {

    constructor() {
        super()
        this.state = {
            totalQuestionaryInteractions: 0
        }
    }

    componentDidMount = async () => {
        const result = await API.graphql(graphqlOperation(listQuestionaryInteractions, {authMode: 'API_KEY'}))
        if (result.data.listQuestionaryInteractions.items.length > 0) {
            this.setState({totalQuestionaryInteractions: result.data.listQuestionaryInteractions.items.length})
        }
        
    }

    render() {
        return (
                <div className="container">
                    <Alert key="1" variant="info">
                        Número de cuestionarios hechos: {this.state.totalQuestionaryInteractions}
                    </Alert>
                </div>   
        )
    }
}

export default Dashboard