/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateQuestionaryConfig = /* GraphQL */ `
  subscription OnCreateQuestionaryConfig {
    onCreateQuestionaryConfig {
      id
      isShowReactCursorPositionLabel
      isCreateInteractionQuestionary
      isCreateInteractionQuestionaryButton
      isDataClient
      externalLink
      isExternalLink
      layout
      startTextHTML
      endTextHTML
    }
  }
`;
export const onUpdateQuestionaryConfig = /* GraphQL */ `
  subscription OnUpdateQuestionaryConfig {
    onUpdateQuestionaryConfig {
      id
      isShowReactCursorPositionLabel
      isCreateInteractionQuestionary
      isCreateInteractionQuestionaryButton
      isDataClient
      externalLink
      isExternalLink
      layout
      startTextHTML
      endTextHTML
    }
  }
`;
export const onDeleteQuestionaryConfig = /* GraphQL */ `
  subscription OnDeleteQuestionaryConfig {
    onDeleteQuestionaryConfig {
      id
      isShowReactCursorPositionLabel
      isCreateInteractionQuestionary
      isCreateInteractionQuestionaryButton
      isDataClient
      externalLink
      isExternalLink
      layout
      startTextHTML
      endTextHTML
    }
  }
`;
export const onCreateQuestionary = /* GraphQL */ `
  subscription OnCreateQuestionary {
    onCreateQuestionary {
      id
      name
      isEnable
      questions {
        items {
          id
          question
          questionaryID
          isEnable
          orderNumber
        }
        nextToken
      }
      questionaryInteractions {
        items {
          id
          clientID
          utm
          ip
          browser
          questionaryStartTime
          questionaryEndTime
          isBrowser
          isMobile
          state_0
          state_1
          state_2
          dataClientJson
          dataClientFormJson
          questionaryID
        }
        nextToken
      }
    }
  }
`;
export const onUpdateQuestionary = /* GraphQL */ `
  subscription OnUpdateQuestionary {
    onUpdateQuestionary {
      id
      name
      isEnable
      questions {
        items {
          id
          question
          questionaryID
          isEnable
          orderNumber
        }
        nextToken
      }
      questionaryInteractions {
        items {
          id
          clientID
          utm
          ip
          browser
          questionaryStartTime
          questionaryEndTime
          isBrowser
          isMobile
          state_0
          state_1
          state_2
          dataClientJson
          dataClientFormJson
          questionaryID
        }
        nextToken
      }
    }
  }
`;
export const onDeleteQuestionary = /* GraphQL */ `
  subscription OnDeleteQuestionary {
    onDeleteQuestionary {
      id
      name
      isEnable
      questions {
        items {
          id
          question
          questionaryID
          isEnable
          orderNumber
        }
        nextToken
      }
      questionaryInteractions {
        items {
          id
          clientID
          utm
          ip
          browser
          questionaryStartTime
          questionaryEndTime
          isBrowser
          isMobile
          state_0
          state_1
          state_2
          dataClientJson
          dataClientFormJson
          questionaryID
        }
        nextToken
      }
    }
  }
`;
export const onCreateQuestionaryInteraction = /* GraphQL */ `
  subscription OnCreateQuestionaryInteraction {
    onCreateQuestionaryInteraction {
      id
      clientID
      utm
      ip
      browser
      questionaryStartTime
      questionaryEndTime
      isBrowser
      isMobile
      state_0
      state_1
      state_2
      dataClientJson
      dataClientFormJson
      questionaryID
      questionary {
        id
        name
        isEnable
        questions {
          nextToken
        }
        questionaryInteractions {
          nextToken
        }
      }
      interactions {
        items {
          id
          groupInteractionId
          type
          element
          epoch
          isMouseDetected
          isTouchDetected
          height
          width
          isActive
          isActiveClick
          isActiveTouch
          isPositionOutside
          x
          y
          distance
          speed
          speedAverage
          sumTimeMiliseconds
          dt
          sumDistance
          countOutside
          distance_questionary_point
          distance_left_button_point
          distance_right_button_point
          sumTimeMilisecondsBeforeNextQuestion
          questionID
          questionaryInteractionID
        }
        nextToken
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const onUpdateQuestionaryInteraction = /* GraphQL */ `
  subscription OnUpdateQuestionaryInteraction {
    onUpdateQuestionaryInteraction {
      id
      clientID
      utm
      ip
      browser
      questionaryStartTime
      questionaryEndTime
      isBrowser
      isMobile
      state_0
      state_1
      state_2
      dataClientJson
      dataClientFormJson
      questionaryID
      questionary {
        id
        name
        isEnable
        questions {
          nextToken
        }
        questionaryInteractions {
          nextToken
        }
      }
      interactions {
        items {
          id
          groupInteractionId
          type
          element
          epoch
          isMouseDetected
          isTouchDetected
          height
          width
          isActive
          isActiveClick
          isActiveTouch
          isPositionOutside
          x
          y
          distance
          speed
          speedAverage
          sumTimeMiliseconds
          dt
          sumDistance
          countOutside
          distance_questionary_point
          distance_left_button_point
          distance_right_button_point
          sumTimeMilisecondsBeforeNextQuestion
          questionID
          questionaryInteractionID
        }
        nextToken
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const onDeleteQuestionaryInteraction = /* GraphQL */ `
  subscription OnDeleteQuestionaryInteraction {
    onDeleteQuestionaryInteraction {
      id
      clientID
      utm
      ip
      browser
      questionaryStartTime
      questionaryEndTime
      isBrowser
      isMobile
      state_0
      state_1
      state_2
      dataClientJson
      dataClientFormJson
      questionaryID
      questionary {
        id
        name
        isEnable
        questions {
          nextToken
        }
        questionaryInteractions {
          nextToken
        }
      }
      interactions {
        items {
          id
          groupInteractionId
          type
          element
          epoch
          isMouseDetected
          isTouchDetected
          height
          width
          isActive
          isActiveClick
          isActiveTouch
          isPositionOutside
          x
          y
          distance
          speed
          speedAverage
          sumTimeMiliseconds
          dt
          sumDistance
          countOutside
          distance_questionary_point
          distance_left_button_point
          distance_right_button_point
          sumTimeMilisecondsBeforeNextQuestion
          questionID
          questionaryInteractionID
        }
        nextToken
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const onCreateQuestion = /* GraphQL */ `
  subscription OnCreateQuestion {
    onCreateQuestion {
      id
      question
      questionaryID
      isEnable
      orderNumber
      questionary {
        id
        name
        isEnable
        questions {
          nextToken
        }
        questionaryInteractions {
          nextToken
        }
      }
      options {
        items {
          id
          title
          orderNumber
          questionID
        }
        nextToken
      }
      interactions {
        items {
          id
          groupInteractionId
          type
          element
          epoch
          isMouseDetected
          isTouchDetected
          height
          width
          isActive
          isActiveClick
          isActiveTouch
          isPositionOutside
          x
          y
          distance
          speed
          speedAverage
          sumTimeMiliseconds
          dt
          sumDistance
          countOutside
          distance_questionary_point
          distance_left_button_point
          distance_right_button_point
          sumTimeMilisecondsBeforeNextQuestion
          questionID
          questionaryInteractionID
        }
        nextToken
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const onUpdateQuestion = /* GraphQL */ `
  subscription OnUpdateQuestion {
    onUpdateQuestion {
      id
      question
      questionaryID
      isEnable
      orderNumber
      questionary {
        id
        name
        isEnable
        questions {
          nextToken
        }
        questionaryInteractions {
          nextToken
        }
      }
      options {
        items {
          id
          title
          orderNumber
          questionID
        }
        nextToken
      }
      interactions {
        items {
          id
          groupInteractionId
          type
          element
          epoch
          isMouseDetected
          isTouchDetected
          height
          width
          isActive
          isActiveClick
          isActiveTouch
          isPositionOutside
          x
          y
          distance
          speed
          speedAverage
          sumTimeMiliseconds
          dt
          sumDistance
          countOutside
          distance_questionary_point
          distance_left_button_point
          distance_right_button_point
          sumTimeMilisecondsBeforeNextQuestion
          questionID
          questionaryInteractionID
        }
        nextToken
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const onDeleteQuestion = /* GraphQL */ `
  subscription OnDeleteQuestion {
    onDeleteQuestion {
      id
      question
      questionaryID
      isEnable
      orderNumber
      questionary {
        id
        name
        isEnable
        questions {
          nextToken
        }
        questionaryInteractions {
          nextToken
        }
      }
      options {
        items {
          id
          title
          orderNumber
          questionID
        }
        nextToken
      }
      interactions {
        items {
          id
          groupInteractionId
          type
          element
          epoch
          isMouseDetected
          isTouchDetected
          height
          width
          isActive
          isActiveClick
          isActiveTouch
          isPositionOutside
          x
          y
          distance
          speed
          speedAverage
          sumTimeMiliseconds
          dt
          sumDistance
          countOutside
          distance_questionary_point
          distance_left_button_point
          distance_right_button_point
          sumTimeMilisecondsBeforeNextQuestion
          questionID
          questionaryInteractionID
        }
        nextToken
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const onCreateOption = /* GraphQL */ `
  subscription OnCreateOption {
    onCreateOption {
      id
      title
      orderNumber
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const onUpdateOption = /* GraphQL */ `
  subscription OnUpdateOption {
    onUpdateOption {
      id
      title
      orderNumber
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const onDeleteOption = /* GraphQL */ `
  subscription OnDeleteOption {
    onDeleteOption {
      id
      title
      orderNumber
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      questionAnswers {
        items {
          id
          questionID
          optionID
          questionaryInteractionID
        }
        nextToken
      }
    }
  }
`;
export const onCreateQuestionAnswer = /* GraphQL */ `
  subscription OnCreateQuestionAnswer {
    onCreateQuestionAnswer {
      id
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      optionID
      option {
        id
        title
        orderNumber
        questionID
        question {
          id
          question
          questionaryID
          isEnable
          orderNumber
        }
        questionAnswers {
          nextToken
        }
      }
      questionaryInteractionID
      questionaryInteraction {
        id
        clientID
        utm
        ip
        browser
        questionaryStartTime
        questionaryEndTime
        isBrowser
        isMobile
        state_0
        state_1
        state_2
        dataClientJson
        dataClientFormJson
        questionaryID
        questionary {
          id
          name
          isEnable
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateQuestionAnswer = /* GraphQL */ `
  subscription OnUpdateQuestionAnswer {
    onUpdateQuestionAnswer {
      id
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      optionID
      option {
        id
        title
        orderNumber
        questionID
        question {
          id
          question
          questionaryID
          isEnable
          orderNumber
        }
        questionAnswers {
          nextToken
        }
      }
      questionaryInteractionID
      questionaryInteraction {
        id
        clientID
        utm
        ip
        browser
        questionaryStartTime
        questionaryEndTime
        isBrowser
        isMobile
        state_0
        state_1
        state_2
        dataClientJson
        dataClientFormJson
        questionaryID
        questionary {
          id
          name
          isEnable
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteQuestionAnswer = /* GraphQL */ `
  subscription OnDeleteQuestionAnswer {
    onDeleteQuestionAnswer {
      id
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      optionID
      option {
        id
        title
        orderNumber
        questionID
        question {
          id
          question
          questionaryID
          isEnable
          orderNumber
        }
        questionAnswers {
          nextToken
        }
      }
      questionaryInteractionID
      questionaryInteraction {
        id
        clientID
        utm
        ip
        browser
        questionaryStartTime
        questionaryEndTime
        isBrowser
        isMobile
        state_0
        state_1
        state_2
        dataClientJson
        dataClientFormJson
        questionaryID
        questionary {
          id
          name
          isEnable
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
    }
  }
`;
export const onCreateInteraction = /* GraphQL */ `
  subscription OnCreateInteraction {
    onCreateInteraction {
      id
      groupInteractionId
      type
      element
      epoch
      isMouseDetected
      isTouchDetected
      height
      width
      isActive
      isActiveClick
      isActiveTouch
      isPositionOutside
      x
      y
      distance
      speed
      speedAverage
      sumTimeMiliseconds
      dt
      sumDistance
      countOutside
      distance_questionary_point
      distance_left_button_point
      distance_right_button_point
      sumTimeMilisecondsBeforeNextQuestion
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      questionaryInteractionID
      questionaryInteraction {
        id
        clientID
        utm
        ip
        browser
        questionaryStartTime
        questionaryEndTime
        isBrowser
        isMobile
        state_0
        state_1
        state_2
        dataClientJson
        dataClientFormJson
        questionaryID
        questionary {
          id
          name
          isEnable
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateInteraction = /* GraphQL */ `
  subscription OnUpdateInteraction {
    onUpdateInteraction {
      id
      groupInteractionId
      type
      element
      epoch
      isMouseDetected
      isTouchDetected
      height
      width
      isActive
      isActiveClick
      isActiveTouch
      isPositionOutside
      x
      y
      distance
      speed
      speedAverage
      sumTimeMiliseconds
      dt
      sumDistance
      countOutside
      distance_questionary_point
      distance_left_button_point
      distance_right_button_point
      sumTimeMilisecondsBeforeNextQuestion
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      questionaryInteractionID
      questionaryInteraction {
        id
        clientID
        utm
        ip
        browser
        questionaryStartTime
        questionaryEndTime
        isBrowser
        isMobile
        state_0
        state_1
        state_2
        dataClientJson
        dataClientFormJson
        questionaryID
        questionary {
          id
          name
          isEnable
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteInteraction = /* GraphQL */ `
  subscription OnDeleteInteraction {
    onDeleteInteraction {
      id
      groupInteractionId
      type
      element
      epoch
      isMouseDetected
      isTouchDetected
      height
      width
      isActive
      isActiveClick
      isActiveTouch
      isPositionOutside
      x
      y
      distance
      speed
      speedAverage
      sumTimeMiliseconds
      dt
      sumDistance
      countOutside
      distance_questionary_point
      distance_left_button_point
      distance_right_button_point
      sumTimeMilisecondsBeforeNextQuestion
      questionID
      question {
        id
        question
        questionaryID
        isEnable
        orderNumber
        questionary {
          id
          name
          isEnable
        }
        options {
          nextToken
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
      questionaryInteractionID
      questionaryInteraction {
        id
        clientID
        utm
        ip
        browser
        questionaryStartTime
        questionaryEndTime
        isBrowser
        isMobile
        state_0
        state_1
        state_2
        dataClientJson
        dataClientFormJson
        questionaryID
        questionary {
          id
          name
          isEnable
        }
        interactions {
          nextToken
        }
        questionAnswers {
          nextToken
        }
      }
    }
  }
`;
