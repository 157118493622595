import React, { Component } from 'react'

// Auth
import { withAuthenticator } from 'aws-amplify-react';

// Routing
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom'

// Components

import Header from './Header/Header'
import Footer from './Footer/Footer'
import QuestionaryConfig from './QuestionaryConfig/QuestionaryConfig'
import CRUDQuestionary from './Questionary/CRUDQuestionary'
import QuestionaryInteraction from './QuestionaryInteraction/QuestionaryInteraction'
import Dashboard from './Dashboard/Dashboard'



class Admon extends Component {

    constructor() {
        super()
        this.state = {
            totalQuestionaryInteractions: 0
        }
    }

    componentDidMount = async () => {
        
    }

    render() {
        return (
            <Router>
                <Header/>
                <Switch>
                    <Route path="/admon/questionaryConfig" component={QuestionaryConfig}/>
                    <Route path="/admon/crud_questionary" component={CRUDQuestionary}/>
                    <Route path="/admon/update_questionary_interaction" component={QuestionaryInteraction}/>
                    <Route path="/admon/dashboard" component={Dashboard}/>
                </Switch>
                <Footer/>
            </Router>
        )
    }
}


export default withAuthenticator(Admon, {
    signInConfig: {
        isSignUpDisplayed: false
    }
  })


// export default withAuthenticator(Admon, {
//     signUpConfig: {
//         hiddenDefaults: ["phone_number"],
//         signUpFields: [
//         { label: "Name", key: "name", required: true, type: "string" }
//       ]
//   }})
