import React, { Component } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { updateQuestion, updateOption } from '../../../graphql/mutations'
import { listQuestionarys} from '../../../graphql/queries'
import { CheckBox, View, Text, StyleSheet } from 'react-native'
import { Button, Alert } from 'react-bootstrap'
// import { onUpdateQuestionaryConfig } from '../../../graphql/subscriptions'


class CRUDQuestionary extends Component {

  constructor() {
    super()
    this.state = {
        ID: null,
        name: "",
        isEnable: false,
        isCRUDButtonDisable: true,
        questionaries: null,
        isQuestionariesLoaded: false,
        questions: [],
    }
  }

  componentDidMount = async () => {
    await this.loadQuestionaries()

    // this.updateQuestionaryConfigListener = API.graphql(graphqlOperation(onUpdateQuestionaryConfig))
    //     .subscribe({
    //         next: questionaryConfigData => {
    //             const updatedQuestionaryConfig = questionaryConfigData.value.data.onUpdateQuestionaryConfig
    //             this.setState({
    //               ID: updatedQuestionaryConfig.id,
    //               isShowReactCursorPositionLabel: updatedQuestionaryConfig.isShowReactCursorPositionLabel,
    //               isCreateInteractionQuestionary: updatedQuestionaryConfig.isCreateInteractionQuestionary,
    //               isCreateInteractionQuestionaryButton: updatedQuestionaryConfig.isCreateInteractionQuestionaryButton,
    //               isDataClient: updatedQuestionaryConfig.isDataClient,
    //               externalLink: updatedQuestionaryConfig.externalLink,
    //               isExternalLink: updatedQuestionaryConfig.isExternalLink,
    //               isCRUDButtonDisable: false,
    //           }) 
    //           console.log('componentDidMount updateQuestionaryConfigListener')
    //         }
    //     })
  }

  loadQuestionaries = async () => {
    const result = await API.graphql(graphqlOperation(listQuestionarys, {authMode: 'API_KEY'}))
    console.log(" loadQuestionary: ", result.data.listQuestionarys.items)
    if (result.data.listQuestionarys.items.length > 0) {
      let tempQuestions = result.data.listQuestionarys.items[0].questions.items
      // Ordering questions
      tempQuestions.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : -1)
      // Ordering options
      tempQuestions.map( (question) => {
          question.options.items.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : -1)
          return question
      })
      result.data.listQuestionarys.items = tempQuestions
      this.setState({
          questionaries: result.data.listQuestionarys.items,
          isQuestionariesLoaded: true,
          questions: tempQuestions
      })
    }
  }

  handleChangeExternalLink = event => this.setState({
    [event.target.name] : event.target.value
  })

  handleUpdateQuestions = async event => {
    let tempQuestions = this.state.questions.map(question => {
      let tempOptions = question.options.items.map(option => {
        API.graphql(graphqlOperation(updateOption, { input: option }))
        return option
      })
      delete question.options
      API.graphql(graphqlOperation(updateQuestion, { input: question }))
      return question
    })

  }

  setSelectionIsQuestionEnable = async(pQuestion, event) => {
    let tempQuestions = this.state.questions.map(question => {
      if (pQuestion.id === question.id) {
        if (pQuestion.isEnable){
          pQuestion.isEnable = false
        }else{
          pQuestion.isEnable = true
        }
      }
      return question
    })
    await this.setState({questions: tempQuestions, isCRUDButtonDisable: false})
  }

  handleChangeQuestionQuestion = async(pQuestion, event) => {
    let tempQuestions = this.state.questions.map(question => {
      if (pQuestion.id === question.id) {
        question.question = event.target.value
      }
      return question
    })
    await this.setState({questions: tempQuestions, isCRUDButtonDisable: false})
  }

  handleChangeQuestionOption = async(pQuestion, pOption, event) => {
    let tempQuestions = this.state.questions.map(question => {
      if (pQuestion.id === question.id) {
        let tempOptions = question.options.items.map(option => {
          if (pOption.id === option.id) {
            option.title = event.target.value
          }
          return option
        })
        question.options.items = tempOptions
      }
      return question
    })
    
    await this.setState({questions: tempQuestions, isCRUDButtonDisable: false})
  }

  handleUpOrderNumberQuestion = async(pQuestion, event) => {
    let isUpdateNextOne = false
    let tempQuestions = this.state.questions.map(question => {
      
      if (isUpdateNextOne) {
        question.orderNumber -= 1
        isUpdateNextOne = false
      }

      if (pQuestion.id === question.id) {
        if (question.orderNumber !== this.state.questions.length) {
          question.orderNumber += 1
          isUpdateNextOne = true
        }
      }
      
      return question
    })
    // Ordering asc questions
    tempQuestions.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : -1)
    await this.setState({questions: tempQuestions, isCRUDButtonDisable: false})
  }

  handleUpOrderNumberQuestionOption = async(pQuestion, pOption, event) => {
    let tempQuestions = this.state.questions.map(question => {
      if (pQuestion.id === question.id) {
        if (question.options.items.length === 2) {
          if (question.options.items[0].orderNumber === 1) {
            question.options.items[0].orderNumber = 2
            question.options.items[1].orderNumber = 1
          } else {
            question.options.items[0].orderNumber = 1
            question.options.items[1].orderNumber = 2
          }
        }
      }
      return question
    })

    // Ordering asc options
    tempQuestions.map( (question) => {
      question.options.items.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : -1)
      return question
    })

    await this.setState({questions: tempQuestions, isCRUDButtonDisable: false})
  }

  handleDownOrderNumberQuestion = async(pQuestion, event) => {
    let isUpdateNextOne = false
    // Ordering desc questions
    let tempQuestionsOrderDesc = this.state.questions.sort((a, b) => (a.orderNumber < b.orderNumber) ? 1 : -1)

    let tempQuestions = tempQuestionsOrderDesc.map(question => {
      if (isUpdateNextOne) {
        question.orderNumber += 1
        isUpdateNextOne = false
      }

      if (pQuestion.id === question.id) {
        if (question.orderNumber !== 1) {
          question.orderNumber -= 1
          isUpdateNextOne = true
        }
      }
      
      return question
    })
    // Ordering questions
    tempQuestions.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : -1)
    await this.setState({questions: tempQuestions, isCRUDButtonDisable: false})
  }


  render() {
    let {isQuestionariesLoaded} = this.state
    let {questions} = this.state

    const paddingStyle = {
      backgroundColor: "white",
      padding: "10px",
      marginTop: 30,
    }

    const renderQuestionaries = () => {
      if(isQuestionariesLoaded){
        return (
          <table>
            <tbody>
              {this.state.questionaries.map(questionary => (
                <tr key={questionary.id}>
                  <td>
                    {questionary.name}
                  </td>
                  <td>
                    {questionary.isEnable}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )
      }
    }

    const renderQuestions = () => {

      if (questions.length > 0) {

        return (
          
            <div className="container">

              {this.state.questions.map(question => (

                <div className="row" key={question.id} style={paddingStyle}>

                    <div className="col-md-3">
                      <View style={styles.checkboxContainer}>
                          <CheckBox
                          value={question.isEnable}
                          onValueChange={(e) => this.setSelectionIsQuestionEnable(question, e)}
                          style={styles.checkbox}
                          />
                          <Text style={styles.label}>Activo</Text>
                      </View>
                    </div>

                    <div className="col-md-3">
                        <input  
                            type="text" 
                            placeholder="Pregunta"
                            name="pregunta"
                            rows="2"
                            cols="100"
                            value={question.question}
                            onChange={(e) => this.handleChangeQuestionQuestion(question, e)}
                        />
                    </div>

                    <div className="col-md-3">
                      <Alert key="1" variant="info">
                        {question.orderNumber}
                      </Alert>
                    </div>

                    <div className="col-md-3">
                      <Button variant="primary" size="sm" onClick={(e) => this.handleUpOrderNumberQuestion(question, e)}>
                        Subir
                      </Button>{' '}
                      <Button variant="secondary" size="sm" onClick={(e) => this.handleDownOrderNumberQuestion(question, e)}> 
                        Bajar
                      </Button>
                    </div>

                    <div className="row">
                      {renderOptions(question)}
                    </div>

                </div>
                
              ))}
            </div>

        )
      }
      
    }

    const renderOptions = (question) => {

      if (question.options.items.length > 0) {

        return (
          
            <div className="container">

              {question.options.items.map(option => (

                <div className="row" key={option.id}>

                    <div className="col-md-6">
                        <input  
                            type="text" 
                            placeholder="Opción"
                            name="opción"
                            rows="2"
                            cols="50"
                            value={option.title}
                            onChange={(e) => this.handleChangeQuestionOption(question, option, e)}
                        />
                    </div>

                    <div className="col-md-6">
                      <Button variant="primary" size="sm" onClick={(e) => this.handleUpOrderNumberQuestionOption(question, option, e)}>
                        Cambiar
                      </Button>                    
                    </div>

                </div>
              ))}
            </div>

        )
      }
      
    }

    return (
      <div className="container">
        
        <div className="row">
          {renderQuestionaries()}
        </div>

        
        {renderQuestions()}
        

        <div className="row">      
          
          <div className="col-md-12">
            <Button 
                variant="primary" 
                size="lg" 
                block
                onClick={this.handleUpdateQuestions}
                disabled={this.state.isCRUDButtonDisable}
                >ACTUALIZAR</Button>
          </div>
          
        </div>
    </div>
        
      )
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  checkboxContainer: {
    flexDirection: "row",
    marginBottom: 20,
  },
  checkbox: {
    alignSelf: "center",
  },
  label: {
    margin: 8,
  },
  baseText: {
    fontFamily: "Cochin"
  },
  titleText: {
    fontSize: 20,
    fontWeight: "bold"
  }
})

export default CRUDQuestionary