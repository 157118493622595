import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

export default class Footer extends Component {
   
    render() {
        return (
            <Container fluid>
                <Row>
                    <Col>Copyright</Col>
                </Row>
            </Container>
        )
    }
}
