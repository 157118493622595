// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://7vwgjop7w5c23gvex3w4eeof7a.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-u2wi3kujrjdfrcju7yvdnmwum4",
    "aws_cognito_identity_pool_id": "us-east-1:321f8894-d5df-42f1-b741-f9bdd03015ca",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_fiFo6swM7",
    "aws_user_pools_web_client_id": "60fs3f9rjtp9mjbelmr7i0e6uk",
    "oauth": {}
};


export default awsmobile;
