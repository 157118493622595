import React, { Component } from 'react'
import End from './End'
import EndDataClient from './EndDataClient'
import QuestionHeader from './QuestionHeader'
// Cursor Position
import ReactCursorPosition from 'react-cursor-position'
import PositionLabel from './PositionLabel'
// Device detect
import {isBrowser, isMobile, isMobileSafari, isChromium, isAndroid, isWinPhone, isIOS, isChrome, isFirefox, isSafari, isOpera, isIE, isEdge, isYandex} from "react-device-detect"
import axios from "axios"
// Amplify
import { API, graphqlOperation } from 'aws-amplify'
import { createQuestionaryInteraction, createInteraction, createQuestionAnswer } from '../graphql/mutations'
import { v4 as uuidv4 } from 'uuid'
import { listQuestionarys, listQuestionaryConfigs} from '../graphql/queries'

export default class Questionary extends Component {

    constructor() {
        super()
        this.state = {
            isShowReactCursorPositionLabel: false, // Questionary config
            isCreateInteractionQuestionary: false,
            isCreateInteractionQuestionaryButton: false,
            isDataClient: false,
            questionary: null,
            indexQuestion: 0, // Questionary
            isEndedQuestionary: false,
            questionaryStartTime: 0,
            questionaryEndTime: 0,
            browser: "",
            os: "",
            isBrowser: false,
            isMobile: false,
            utm: "",
            questions: [],
            ip: "",
            isActive: false,
            listQuestionariesDB: [],
            listActualInteractionsDB: [],
            listActualInteractionsQuestionary: [],
            listActualInteractionsButton: [],
            questionaryInteractionCreated: null,
            isQuestionaryInteractionCreated: false,
            actualQuestionStartTime: 0,
            questionaryCountOutside: 0,
            questionaryIsPositionOutside: false,
            groupInteractionId: "",
            questionaryButtonCountOutside: 0,
            actualQuestionaryButtonOption: {isSelected: false, option_id: 1, title: ""},
            sumTimeMilisecondsBeforeNextQuestion: 0,
        }
    }

    async componentDidMount()  {
        // console.log('### componentDidMount: ', this.props)
        let actualEpochTime = Date.now()
        // this.updateQuestionTime(actualEpochTime, false)
        this.setState({ questionaryStartTime: actualEpochTime })

        if (isBrowser) {
            this.setState({ isBrowser: true })
        }

        if (isMobile) {
            this.setState({ isMobile: true })
        }
        await this.loadIP()
        const utm = localStorage.getItem('utm')

        this.setState({utm: utm})

        this.loadBrowserOrOS()

        this.setState({groupInteractionId: uuidv4().substring(1,12)})

        await this.loadQuestionaryConfig()
        await this.loadQuestionary()
        await this.addQuestionaryInteraction()

        

    }

    componentDidUpdate() {
        
    }

    async loadIP () {
        axios
            .get("https://jsonip.com/")
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ip: response.data.ip})
                }
            })
            .catch((error) => {
                console.log("### loadProjectWarehouses Error: ", error)
            })
    }

    loadBrowserOrOS () {
        if (isMobileSafari) {
            this.setState({browser: "Safari"})
        }

        if (isChromium) {
            this.setState({browser: "Chromium"})
        }


        if (isAndroid) {
            this.setState({os: "Android"})
        }

        if (isWinPhone) {
            this.setState({os: "WinPhone"})
        }

        if (isIOS) {
            this.setState({os: "IOS"})
        }

        if (isChrome) {
            this.setState({browser: "Chrome"})
        }

        if (isFirefox) {
            this.setState({browser: "Firefox"})
        }

        if (isSafari) {
            this.setState({browser: "Safari"})
        }

        if (isOpera) {
            this.setState({browser: "Opera"})
        }

        if (isIE) {
            this.setState({browser: "IE"})
        }

        if (isEdge) {
            this.setState({browser: "Edge"})
        }

        if (isYandex) {
            this.setState({browser: "Yandex"})
        }

    }

    async nextQuestion (pOption) {
        let tempIndexQuestion = this.state.indexQuestion
        let actualEpochTime = Date.now()

        this.setState({sumTimeMilisecondsBeforeNextQuestion: 0})

        if ((this.state.questions.length - 1) === tempIndexQuestion) {
            this.setState({isEndedQuestionary: true, questionaryEndTime: actualEpochTime})
            console.log('####### nextQuestion this.state.listQuestionariesDB: ',this.state.listQuestionariesDB)
        }else {
            console.log('#### nextQuestion this.state.listActualInteractionsQuestionary.length: ', this.state.listActualInteractionsQuestionary.length)
            this.state.listActualInteractionsQuestionary.forEach(async interaction => {
                let interactionInput = {
                    type: interaction.type,
                    element: interaction.element,
                    epoch: interaction.epoch,
                    isMouseDetected: interaction.isMouseDetected,
                    isTouchDetected: interaction.isTouchDetected,
                    height: interaction.height,
                    width: interaction.width,
                    isActive: interaction.isActive,
                    isActiveClick: interaction.isActiveClick,
                    isActiveTouch: interaction.isActiveTouch,
                    isPositionOutside: interaction.isPositionOutside,
                    x: interaction.x,
                    y: interaction.y,
                    distance: interaction.distance,
                    speed: interaction.speed,
                    speedAverage: interaction.speedAverage,
                    sumTimeMiliseconds: interaction.sumTimeMiliseconds,
                    dt: interaction.dt,
                    sumDistance: interaction.sumDistance,
                    countOutside: interaction.countOutside,
                    distance_questionary_point: interaction.distance_questionary_point,
                    distance_left_button_point: interaction.distance_left_button_point,
                    distance_right_button_point: interaction.distance_right_button_point,
                    sumTimeMilisecondsBeforeNextQuestion: interaction.sumTimeMilisecondsBeforeNextQuestion,
                    questionID: interaction.questionID,
                    questionaryInteractionID: interaction.questionaryInteractionID,
                }
                if (this.state.isCreateInteractionQuestionary) {
                    await API.graphql(graphqlOperation(createInteraction, { input: interactionInput }, 'API_KEY'))
                }
            }
            )
            
            this.state.listActualInteractionsButton.forEach(async interaction => {
                let interactionInput = {
                    type: interaction.type,
                    element: interaction.element,
                    epoch: interaction.epoch,
                    isMouseDetected: interaction.isMouseDetected,
                    isTouchDetected: interaction.isTouchDetected,
                    height: interaction.height,
                    width: interaction.width,
                    isActive: interaction.isActive,
                    isActiveClick: interaction.isActiveClick,
                    isActiveTouch: interaction.isActiveTouch,
                    isPositionOutside: interaction.isPositionOutside,
                    x: interaction.x,
                    y: interaction.y,
                    distance: interaction.distance,
                    speed: interaction.speed,
                    speedAverage: interaction.speedAverage,
                    sumTimeMiliseconds: interaction.sumTimeMiliseconds,
                    dt: interaction.dt,
                    sumDistance: interaction.sumDistance,
                    countOutside: interaction.countOutside,
                    questionID: interaction.questionID,
                    questionaryInteractionID: interaction.questionaryInteractionID,
                }
                if (this.state.isCreateInteractionQuestionaryButton) {
                    await API.graphql(graphqlOperation(createInteraction, { input: interactionInput }, 'API_KEY'))
                }
            }
            )
            // createQuestionAnswer
            let questionAnswerInput = {
                questionaryInteractionID: this.state.questionaryInteractionCreated.id,
                questionID: this.state.questions[this.state.indexQuestion].id,
                optionID: pOption.id,
            }

            await API.graphql(graphqlOperation(createQuestionAnswer, { input: questionAnswerInput }, 'API_KEY'))

            tempIndexQuestion = tempIndexQuestion + 1
            this.setState({indexQuestion: tempIndexQuestion, actualQuestionStartTime: actualEpochTime, listActualInteractionsDB: []})
        }
        localStorage.setItem('state', this.state)

    }

    onPositionChangeQuestionary = (props) => {
        // console.log('### onPositionChangeQuestionary: ', props)
        let actualEpochTime = Date.now()
        let isMouseDetected = false
        let isTouchDetected = false
        let height = 0
        let width = 0
        let countOutside = 0
        let x_start_questionary_point = 0 // x questionary  reference point
        let dx_start_questionary_point = 0 // x questionary  reference point
        let y_start_questionary_point = 0 // y questionary  reference point
        let dy_start_questionary_point = 0 // y questionary  reference point
        let distance_questionary_point = 0

        let x_left_button_point = 320 // x left button  reference point
        let dx_left_button_point = 0 // x left button  reference point
        let y_left_button_point = 397 // y left button  reference point
        let dy_left_button_point = 0 // y left button  reference point
        let distance_left_button_point = 0

        let x_right_button_point = 470 // x right button  reference point
        let dx_right_button_point = 0 // x right button  reference point
        let y_right_button_point = 397 // y right button  reference point
        let dy_right_button_point = 0 // y right button  reference point
        let distance_right_button_point = 0


        if (props.detectedEnvironment !== undefined) {
            if (props.detectedEnvironment.isMouseDetected !== undefined) {
                isMouseDetected = props.detectedEnvironment.isMouseDetected
            }
            if (props.detectedEnvironment.isTouchDetected !== undefined) {
                isTouchDetected = props.detectedEnvironment.isTouchDetected
            }
        }
        
        if (props.elementDimensions !== undefined) {
            if (props.elementDimensions.height !== undefined) {
                height = props.elementDimensions.height
                y_start_questionary_point = 300
            }
            if (props.elementDimensions.height !== undefined) {
                width = props.elementDimensions.width
                x_start_questionary_point = width/2
            }
        }

        dx_start_questionary_point = props.position.x - x_start_questionary_point
        dy_start_questionary_point = props.position.y - y_start_questionary_point
        distance_questionary_point = Math.sqrt(Math.pow(dx_start_questionary_point, 2) + Math.pow(dy_start_questionary_point, 2))

        dx_left_button_point = props.position.x - x_left_button_point
        dy_left_button_point = props.position.y - y_left_button_point
        distance_left_button_point = Math.sqrt(Math.pow(dx_left_button_point, 2) + Math.pow(dy_left_button_point, 2))

        dx_right_button_point = props.position.x - x_right_button_point
        dy_right_button_point = props.position.y - y_right_button_point
        distance_right_button_point = Math.sqrt(Math.pow(dx_right_button_point, 2) + Math.pow(dy_right_button_point, 2))

        // KPI
        let tempListActualInteractionsQuestionary = this.state.listActualInteractionsQuestionary
        let dx = 0
        let dy = 0
        let distance = 0
        let sumDistance = 0
        let dt = 0
        let speed = 0
        let speedAverage = 0
        let actualLength = tempListActualInteractionsQuestionary.length
        let sumTimeMiliseconds = 0
        

        if ( actualLength > 0) {
            if (props.isPositionOutside !== undefined) {
                if (props.isPositionOutside !== this.state.questionaryIsPositionOutside) {
                    countOutside = this.state.questionaryCountOutside
                    countOutside += 1
                    this.setState({questionaryCountOutside: countOutside, questionaryIsPositionOutside: props.isPositionOutside})
                }
            }

            dx = props.position.x - tempListActualInteractionsQuestionary[actualLength-1].x
            dy = props.position.y - tempListActualInteractionsQuestionary[actualLength-1].y
            dt = actualEpochTime - tempListActualInteractionsQuestionary[actualLength-1].epoch
            distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2))
            if (dt > 0 ) {
                speed = distance/dt
            }else {
                speed = 0
            }
            speedAverage = tempListActualInteractionsQuestionary.reduce(function (sum, interaction) {
                return sum + parseFloat(interaction.speed)
            }, 0) / actualLength

            sumTimeMiliseconds = tempListActualInteractionsQuestionary.reduce(function (sum, interaction) {
                return sum + parseFloat(interaction.dt)
            }, 0)

            sumDistance = tempListActualInteractionsQuestionary.reduce(function (sum, interaction) {
                return sum + parseFloat(interaction.distance)
            }, 0)

            let tempSumTimeMilisecondsBeforeNextQuestion = this.state.sumTimeMilisecondsBeforeNextQuestion
            tempSumTimeMilisecondsBeforeNextQuestion = tempSumTimeMilisecondsBeforeNextQuestion + dt
            this.setState({sumTimeMilisecondsBeforeNextQuestion: tempSumTimeMilisecondsBeforeNextQuestion})
        } 
        
        let interaction = {
                            groupInteractionId: this.state.groupInteractionId,
                            type: 'position', 
                            element: 'questionary', 
                            epoch: actualEpochTime, 
                            isMouseDetected: isMouseDetected,
                            isTouchDetected: isTouchDetected,
                            height: height,
                            width: width,
                            isActive: props.isActive,
                            isPositionOutside: props.isPositionOutside,
                            x: props.position.x,
                            y: props.position.y,
                            distance: distance,
                            speed: speed,
                            speedAverage: speedAverage,
                            sumTimeMiliseconds: sumTimeMiliseconds,
                            dt: dt,
                            sumDistance: sumDistance,
                            countOutside: countOutside,
                            distance_questionary_point: distance_questionary_point,
                            distance_left_button_point: distance_left_button_point,
                            distance_right_button_point: distance_right_button_point,
                            sumTimeMilisecondsBeforeNextQuestion: this.state.sumTimeMilisecondsBeforeNextQuestion,
                            questionID: this.state.questions[this.state.indexQuestion].id,
                            questionaryInteractionID: this.state.questionaryInteractionCreated.id,
                        }
        
        tempListActualInteractionsQuestionary.push(interaction)
        this.setState({listActualInteractionsQuestionary: tempListActualInteractionsQuestionary})
        // console.log('### onPositionChangeQuestionary: listActualInteractionsQuestionary: ', this.state.listActualInteractionsQuestionary)
        // console.log('### Questionary height, width: ', interaction.height, interaction.width)
        // console.log('### Questionary x, y: ', interaction.x, interaction.y)
        console.log('### Distances questionary, left_button, right_button: ', interaction.distance_questionary_point, interaction.distance_left_button_point, interaction.distance_right_button_point)
        console.log('### sumTimeMiliseconds, sumTimeMiliseconds: ', sumTimeMiliseconds)
        console.log('### onPositionChangeQuestionary, sumTimeMilisecondsBeforeNextQuestion: ', interaction.sumTimeMilisecondsBeforeNextQuestion)
    }

    onPositionChangeQuestionaryButton = (props, option) => {
        // console.log('### onPositionChangeQuestionaryButton: ', props)
        // console.log('### onPositionChangeQuestionaryButton: ', option)
        
        let actualEpochTime = Date.now()
        let isMouseDetected = false
        let isTouchDetected = false
        let height = 0
        let width = 0
        let countOutside = 0

        if (props.detectedEnvironment !== undefined) {
            if (props.detectedEnvironment.isMouseDetected !== undefined) {
                isMouseDetected = props.detectedEnvironment.isMouseDetected
            }
            if (props.detectedEnvironment.isTouchDetected !== undefined) {
                isTouchDetected = props.detectedEnvironment.isTouchDetected
            }
        }

        if (props.elementDimensions !== undefined) {
            if (props.elementDimensions.height !== undefined) {
                height = props.elementDimensions.height
            }
            if (props.elementDimensions.width !== undefined) {
                width = props.elementDimensions.width
            }
        }

        // KPI
        let tempListActualInteractionsButton = this.state.listActualInteractionsButton
        let dx = 0
        let dy = 0
        let distance = 0
        let sumDistance = 0
        let dt = 0
        let speed = 0
        let speedAverage = 0
        let actualLength = tempListActualInteractionsButton.length
        let sumTimeMiliseconds = 0
        let isNewInteraction = false
    
        if ( actualLength > 0) {
            if (option !== undefined) {
                if (option.title !== this.state.actualQuestionaryButtonOption.title) {
                    countOutside = this.state.questionaryButtonCountOutside
                    countOutside += 1
                    this.setState(
                        {
                            questionaryButtonCountOutside: countOutside, 
                            actualQuestionaryButtonOption: option,
                            groupInteractionId: uuidv4().substring(1,12)
                        }
                    )
                    isNewInteraction = true
                }
            } 

            if (!isNewInteraction) {
                dx = props.position.x - tempListActualInteractionsButton[actualLength-1].x
                dy = props.position.y - tempListActualInteractionsButton[actualLength-1].y
                dt = actualEpochTime - tempListActualInteractionsButton[actualLength-1].epoch
                distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2))
                if (dt > 0 ) {
                    speed = distance/dt
                }else {
                    speed = 0
                }
                speedAverage = tempListActualInteractionsButton.reduce(function (sum, interaction) {
                    return sum + parseFloat(interaction.speed)
                }, 0) / actualLength
    
                sumTimeMiliseconds = tempListActualInteractionsButton.reduce(function (sum, interaction) {
                    return sum + parseFloat(interaction.dt)
                }, 0)
    
                sumDistance = tempListActualInteractionsButton.reduce(function (sum, interaction) {
                    return sum + parseFloat(interaction.distance)
                }, 0)
            }
        }

        let interaction = {
                            groupInteractionId: this.state.groupInteractionId,
                            type: 'position-button', 
                            element: option, 
                            epoch: actualEpochTime, 
                            isMouseDetected: isMouseDetected,
                            isTouchDetected: isTouchDetected,
                            height: height,
                            width: width,
                            isActive: props.isActive,
                            isPositionOutside: props.isPositionOutside,
                            x: props.position.x,
                            y: props.position.y,
                            distance: distance,
                            speed: speed,
                            speedAverage: speedAverage,
                            sumTimeMiliseconds: sumTimeMiliseconds,
                            dt: dt,
                            sumDistance: sumDistance,
                            countOutside: this.state.questionaryButtonCountOutside,
                            questionID: this.state.questions[this.state.indexQuestion].id,
                            questionaryInteractionID: this.state.questionaryInteractionCreated.id,
                        }
        tempListActualInteractionsButton.push(interaction)
        this.setState({listActualInteractionsButton: tempListActualInteractionsButton})
        // console.log( "onPositionChangeQuestionaryButton: listActualInteractionsButton: ", this.state.listActualInteractionsButton)
        console.log('### Button height, width: ', interaction.height, interaction.width)
        console.log('### Button x, y: ', interaction.x, interaction.y)
    }

    onActivationChangedQuestionary = (state) => {
        console.log('### onPositionChangeQuestionary: ', state)
    }

    onDetectedEnvironmentChangedQuestionary = (detectedEnvironment) => {
        console.log('### onDetectedEnvironmentChangedQuestionary: ', detectedEnvironment)
    }

    onActivationChangedQuestionaryButton = (state) => {
        console.log('### onActivationChangedQuestionaryButton: ', state)
    }

    onDetectedEnvironmentChangedQuestionaryButton = (detectedEnvironment) => {
        console.log('### onDetectedEnvironmentChangedQuestionaryButton: ', detectedEnvironment)
    }

    loadQuestionaryConfig = async () => {
        const result = await API.graphql(graphqlOperation(listQuestionaryConfigs, {authMode: 'API_KEY'}))
        if (result.data.listQuestionaryConfigs.items.length > 0) {
            this.setState({
                isShowReactCursorPositionLabel: result.data.listQuestionaryConfigs.items[0].isShowReactCursorPositionLabel,
                isCreateInteractionQuestionary: result.data.listQuestionaryConfigs.items[0].isCreateInteractionQuestionary,
                isCreateInteractionQuestionaryButton: result.data.listQuestionaryConfigs.items[0].isCreateInteractionQuestionaryButton,
                isDataClient: result.data.listQuestionaryConfigs.items[0].isDataClient,
                endTextHTML: result.data.listQuestionaryConfigs.items[0].endTextHTML,
            }) 
        }
        console.log(" Questionary Config: ", result.data.listQuestionaryConfigs.items)
        console.log(" loadQuestionaryConfig this.state: ", this.state)
    }

    loadQuestionary = async () => {
        const result = await API.graphql(graphqlOperation(listQuestionarys, {authMode: 'API_KEY'}))
        console.log(" loadQuestionary: ", result.data.listQuestionarys.items)
        if (result.data.listQuestionarys.items.length > 0) {
            let tempQuestionary = result.data.listQuestionarys.items[0]
            let tempQuestions = result.data.listQuestionarys.items[0].questions.items
            // Ordering questions
            tempQuestions.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : -1)
            // Ordering options
            tempQuestions.map( (question) => {
                question.options.items.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : -1)
                return question
            })
            console.log(" Questions and Options ordered: ", tempQuestions)
            this.setState({
                questionary: {id: tempQuestionary.id, name: tempQuestionary.name, isEnable: tempQuestionary.isEnable},
                questions: tempQuestions
            })
        }
        // this.state.questions[this.state.indexQuestion].options.items.map( (option) => {
        //     console.log('loadQuestionary: Option: ', option)
        //     return null
        // })
    }

    addQuestionaryInteraction = async event => {
        let clientID = uuidv4().substring(1,7)
        const questionaryInteraction = {
            clientID: clientID,
            utm: this.state.utm,
            ip: this.state.ip,
            browser: this.state.browser,
            questionaryStartTime: this.state.questionaryStartTime,
            questionaryEndTime: this.state.questionaryEndTime,
            isBrowser: this.state.isBrowser,
            isMobile: this.state.isMobile,
            state_0: 1,
            state_1: 0,
            state_2: 0,
            questionaryID: this.state.questionary.id, 
        }
        console.log('# addQuestionaryInteraction questionaryInteraction: ', questionaryInteraction)
        const questionaryInteractionCreatedTemp = await API.graphql(graphqlOperation(createQuestionaryInteraction, { input: questionaryInteraction }, 'API_KEY'))
        this.setState({questionaryInteractionCreated: questionaryInteractionCreatedTemp.data.createQuestionaryInteraction, isQuestionaryInteractionCreated: true})

   }

    render() {
        let {isEndedQuestionary} = this.state
        let {indexQuestion} = this.state
        let {isShowReactCursorPositionLabel} = this.state
        let {isQuestionaryInteractionCreated} = this.state

        const renderPositionLabel = () => {
            if (isShowReactCursorPositionLabel) {
                return (
                    <PositionLabel/>
                )
            }
        }

        const renderButtonOption = (pOption) => {
            let optionsArray = this.state.questions[indexQuestion].options.items
            let optionSide = null
            if (optionsArray[0].option_id === pOption.option_id) {
                optionSide = optionsArray[1]
            }
            else{
                optionSide = optionsArray[0]
            }    
            
            if (optionSide.title.length > 15) {
                return (
                    <button
                        className="btn-opc  copiar-datos Ramblab padding-2lin"
                        type="button"
                        id={pOption.id}
                        onClick={() => this.nextQuestion(pOption)}
                    >
                        {pOption.title}
                    </button>
                )
            }else {
                return (
                    <button
                        className="btn-opc  copiar-datos Ramblab"
                        type="button"
                        id={pOption.id}
                        onClick={() => this.nextQuestion(pOption)}
                    >
                        {pOption.title}
                    </button>
                )
            }

        }

        const renderQuestionary = () => { 
            if (!isEndedQuestionary && this.state.questions.length > 0) {
                // console.log('Questions before render: ', this.state.questions)
                return (
                    <div>
                        <div className="container gral fondo-encuesta">
                            <div className="center-text-desk col-md-12 Ramblar preguntas">
                                <p>{this.state.questions[this.state.indexQuestion].question}</p>
                            </div>
                            {this.state.questions[this.state.indexQuestion].options.items.map(option => (
                                <div className="respuestas centrar" key={option.id}>
                                    <ReactCursorPosition
                                        {...{
                                            className: 'example__target',
                                            onPositionChanged: props => this.onPositionChangeQuestionaryButton(props, option)
                                            }}
                                    >
                                    {renderButtonOption(option)}
                                    {renderPositionLabel(option)}
                                    </ReactCursorPosition>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                )
            } else {
                if (this.state.questionaryInteractionCreated !== null) {
                    if (!this.state.isDataClient) {
                        return (
                            <div>
                                <End clientID={this.state.questionaryInteractionCreated.clientID} endTextHTML={this.state.endTextHTML} ></End>
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                <EndDataClient clientID={this.state.questionaryInteractionCreated.clientID} endTextHTML={this.state.endTextHTML}></EndDataClient>
                            </div>
                        )
                    }
                }
            }
        }

        const renderActiveButton = (pQuestion) => {
            if (!isEndedQuestionary) {
                if ( (indexQuestion + 1) === pQuestion.orderNumber) {
                    return (
                        <button key={pQuestion.id} type="button" className="bullet-active"><span className="dot"></span></button>
                    )
                }else {
                    return (
                        <button key={pQuestion.id} type="button" className="bullet"><span className="dot"></span></button>
                    )
                }
            }
        }

        const renderQuestionHeader = () => {
            if (!isEndedQuestionary) {
                return (
                    <QuestionHeader></QuestionHeader>
                )
            }
        }

        if (this.state.questions.length > 0) {
            if (isQuestionaryInteractionCreated) {
                return (
                <ReactCursorPosition  {...{
                    className: 'example__target',
                    onPositionChanged: props => this.onPositionChangeQuestionary(props)
                    }}>
                    <div className="">
                        
                        <div className="btn-toolbar d-none" role="toolbar" aria-label="Toolbar with button groups">
                            <div className="btn-group mr-2" role="group" aria-label="First group">
                                {this.state.questions.map(question => (
                                    renderActiveButton(question) 
                                ))}
                            </div>
                        </div>
                        {renderQuestionary()}
                    </div>
                    {/* <PositionLabel /> */}
                </ReactCursorPosition>
            )
            }else {
                return (
                    <p></p>
                )
            }
        } else {
            return (
                <p className="text-center">Cargando el cuestionario...</p>
            )
        }

    }   
}
