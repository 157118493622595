import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'
import { listQuestionaryConfigs} from '../graphql/queries'

export default class Start extends Component {

    constructor() {
        super()
        this.state = {
            utm: "",
            startTextHTML: "Complete la siguiente  encuesta y maravíllese con <strong> Amazing Leads</strong>"
        }
    }

    async componentDidMount()  {
        const { location: { search } } = this.props
        console.log('### componentDidMount: search', search)
        this.setState({utm: search})
        localStorage.setItem('utm', search)
        this.loadQuestionaryConfig()
    }

    loadQuestionaryConfig = async () => {
        const result = await API.graphql(graphqlOperation(listQuestionaryConfigs, {authMode: 'API_KEY'}))
        if (result.data.listQuestionaryConfigs.items.length > 0) {
            this.setState({
                startTextHTML: result.data.listQuestionaryConfigs.items[0].startTextHTML
            }) 
        }
    }
    

    render() {
        return (
            <div>
                <div className="container gral">
                    <div>
                        <div className="alert Santander  col-10 col-md-12 " role="alert">
                            <p className="center-text-desk textos centrar col-md-5">{this.state.startTextHTML}</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div>
                        <div className="cont-btn">
                            <img src="images/flechas-btn.png" alt="flechas" className="img-flechas" width="30" height="auto"/>
                            <Link to="/questionary" className="botonchulo Ramblar centrar" >Iniciar Encuesta</Link>
                        {/* <button type="button" class="btn btn-primary">INICIAR ENCUESTA</button> */}
                        </div>
                    </div>
                </div>
                <div className="row footer">
                            
                </div>
            </div>

        )
    }
}
