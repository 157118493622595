import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Start from './components/Start'
// import Footer from './components/Footer'
import Questionary from './components/Questionary'
import Admon from './components/Admon/Admon'

// Routing
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

class App extends Component{
  constructor() {
    super();
    this.state = {
      x: 0,
      y: 0,
      epochTime: 0
    }
  }

  
  render() {
    
    return (   
      <Router>
        
        <div className="">

          <Switch>
            <Route path="/" exact component={Start} />
            <Route path="/questionary" exact component={Questionary} />
            <Route path="/admon" exact component={Admon} />
          </Switch>

        </div>
      </Router>
    )
  }
}

export default App